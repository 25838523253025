<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="90%"
    :width="600"
  >
    <v-card
      class="mx-auto"
      outlined
    >
      <v-card-text
        id="userAlertMessage"
        class="pa-5 subtitle-1"
      >
        <span v-if="!showHtml">{{ message }}</span>
        <div
          v-else
          v-html="parsedMessage"
        ></div>
      </v-card-text>
      <v-divider />
      <v-footer
        padless
        class="pa-1"
      >
        <v-spacer></v-spacer>
        <v-btn
          v-if="isConfirm"
          color="error"
          class="mr-2"
          @click="cancelA()"
        >
          No
        </v-btn>
        <v-btn
          color="success"
          @click="confirmA()"
        >
          {{ isConfirm ? "Yes" : "Ok" }}
        </v-btn>
      </v-footer>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'AlertBox',
  data() {
    return {
      message: null,
      isConfirm: false,
      isAllowingLinks: false,
      visible: false,
      alertPromise: null,
      showHtml: false,
    };
  },
  computed: {
    parsedMessage() {
      // replace all <a> tags with href to rotschy.app domain with a router-link
      // eslint-disable-next-line no-useless-escape
      const regex = /href="(https?:\/\/(?:[a-zA-Z0-9.-]*\.)?rotschy\.app(?:[\w@?^=%&\/~+#-]*))"/g;
      const newMessage = this.message.replace(regex, (match, url) => {
        // eslint-disable-next-line no-useless-escape
        const path = url.replace(/https?:\/\/(?:[a-zA-Z0-9.-]*\.)?rotschy\.app/, '').replace('/#', '');
        // eslint-disable-next-line no-console
        console.log(path);
        return `href="${path}"`;
      });
      // eslint-disable-next-line no-console
      console.log(newMessage);
      return newMessage;
    },
  },
  watch: {
    message() {
      // eslint-disable-next-line no-useless-escape, max-len
      const regex = /(http|ftp|https):\/\/([a-zA-Z0-9.-]*\.)?(rotschy\.app|rinc\.us)([\w@?^=%&\/~+#-]*)/g;
      if (this.isAllowingLinks && regex.test(this.message)) {
        this.showHtml = true;
      } else {
        this.showHtml = false;
      }
    },
  },
  methods: {
    // Used outside of this file.
    show(msg, isConfirm = false, isAllowingLinks = false) {
      this.isConfirm = isConfirm;
      this.isAllowingLinks = isAllowingLinks;
      this.message = msg;
      this.visible = true;
      return new Promise((res) => {
        this.alertPromise = res;
      });
    },
    confirmA() {
      this.alertPromise(true);
      this.visible = false;
    },

    cancelA() {
      this.alertPromise(false);
      this.visible = false;
    },
  },
};
</script>

<style>
#userAlertMessage {
  white-space: pre-line;
}
</style>
